import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Section from "../../components/section";
import { H2 } from '../../css/stylecomp'
import Footer from "../../components/footer";
// https://davcon.mywire.org/interview?i=docassemble.playground3MappingSidaPartnersEvaluations%3Aexecute_survey.yml
function Survey() {
    return (

        <div>

            <div className="iframe-container">

                <iframe src='https://davcon.mywire.org/interview?i=docassemble.playground3MappingSidaPartnersEvaluations%3Aexecute_survey.yml#page1' sandbox="allow-same-origin allow-top-navigation allow-scripts allow-popups allow-forms" allow="fullscreen" title='graph' frameborder='0' scrolling='no'  ></iframe>
            </div>



        </div>
    )
}

export default Survey;
